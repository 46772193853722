body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "IBM-Plex-Sans-Arabic",
    "Kanit" "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* 
.ant-form {
  color: blue;
} */
@font-face {
  font-family: "IBM-Plex-Sans-Arabic";
  src: local("IBM-Plex-Sans-Arabic"),
    url(./assets/fonts/IBM-Plex-Sans-Arabic/IBMPlexSansArabic-Bold.ttf)
      format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "IBM-Plex-Sans-Arabic";
  src: local("IBM-Plex-Sans-Arabic"),
    url(./assets/fonts/IBM-Plex-Sans-Arabic/IBMPlexSansArabic-ExtraLight.ttf)
      format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "IBM-Plex-Sans-Arabic";
  src: local("IBM-Plex-Sans-Arabic"),
    url(./assets/fonts/IBM-Plex-Sans-Arabic/IBMPlexSansArabic-Light.ttf)
      format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "IBM-Plex-Sans-Arabic";
  src: local("IBM-Plex-Sans-Arabic"),
    url(./assets/fonts/IBM-Plex-Sans-Arabic/IBMPlexSansArabic-Medium.ttf)
      format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "IBM-Plex-Sans-Arabic";
  src: local("IBM-Plex-Sans-Arabic"),
    url(./assets/fonts/IBM-Plex-Sans-Arabic/IBMPlexSansArabic-Regular.ttf)
      format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "IBM-Plex-Sans-Arabic";
  src: local("IBM-Plex-Sans-Arabic"),
    url(./assets/fonts/IBM-Plex-Sans-Arabic/IBMPlexSansArabic-SemiBold.ttf)
      format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "IBM-Plex-Sans-Arabic";
  src: local("IBM-Plex-Sans-Arabic"),
    url(./assets/fonts/IBM-Plex-Sans-Arabic/IBMPlexSansArabic-Text.ttf)
      format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "IBM-Plex-Sans-Arabic";
  src: local("IBM-Plex-Sans-Arabic"),
    url(./assets/fonts/IBM-Plex-Sans-Arabic/IBMPlexSansArabic-Thin.ttf)
      format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/fonts/Kanit/Kanit-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/fonts/Kanit/Kanit-BlackItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/fonts/Kanit/Kanit-ExtraBold.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/fonts/Kanit/Kanit-ExtraBoldItalic.ttf) format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/fonts/Kanit/Kanit-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/fonts/Kanit/Kanit-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/fonts/Kanit/Kanit-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/fonts/Kanit/Kanit-SemiBoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/fonts/Kanit/Kanit-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/fonts/Kanit/Kanit-MediumItalic.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/fonts/Kanit/Kanit-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/fonts/Kanit/Kanit-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/fonts/Kanit/Kanit-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/fonts/Kanit/Kanit-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/fonts/Kanit/Kanit-ExtraLight.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/fonts/Kanit/Kanit-ExtraLightItalic.ttf) format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/fonts/Kanit/Kanit-Thin.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/fonts/Kanit/Kanit-ThinItalic.ttf) format("truetype");
  font-weight: 100;
  font-style: italic;
}

/* Scrollbar */
*::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: #ececec;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #ececec;
}

*::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #fbb03b;
}
